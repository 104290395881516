import { createContext, useContext, useMemo, useState } from "react";

type EnvironmentType = {
  name: string;
}
type EnvironmentContextType = {
  environment: EnvironmentType;
  setEnvironment: (user: EnvironmentType) => void;
}

export const EnvironmentContext = createContext({} as EnvironmentContextType);

function useEnvironmentProvider ({children})  {
  const [environment, setEnvironment] = useState({
    name: "Ambiente Nome"
  });

  const environmentProviderValue= useMemo(() => ({ environment, setEnvironment }), [environment, setEnvironment]);

  return (
    <EnvironmentContext.Provider value={environmentProviderValue}>
      {children}
    </EnvironmentContext.Provider>
  )
}

export const useEnvironment = () => useContext(EnvironmentContext);

export default useEnvironmentProvider;