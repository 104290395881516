import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const navigate = useNavigate();

  useEffect(() => {
    let redirect = "/login";

    // SE O USUARIO ESTIVER LOGADO
    if (true) {
      // SE ELE FOR PROFESSOR/GESTOR
      redirect = false ? "/empresas" : "/usuarios";
      navigate(redirect);
    }
  }, []);

  return <></>;
}
