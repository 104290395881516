import React from "react";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

export default function Index() {
  useDocumentTitle("");

  return (
    <>
      <div className="flex flex-col items-center gap-4">
        <h3>Acesse Agora</h3>

        <Link to="/login">
          <button className="btn btn-success">
            Acesse com E-mail e Senha
          </button>
        </Link>

        <button className="btn btn-info btn-xs">
          Não sabe seu login?
        </button>
      </div>

      <div>
        <h2>Orientações para o simulado on-line</h2>

        <p>1. Para acessar o simulado siga as seguintes instruções:</p>
        <p>a. Caso não saiba o código da sua escola ou tenha qualquer dificuldade com seu login, procure a Central de Relacionamento do Cliente da FTD Educação por meio do telefone 0800 7722300 ou e-mail: central.relacionamento@ftd.com.br.</p>
      </div>
    </>
  );
}
