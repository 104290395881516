import { createContext, useContext, useMemo, useState } from "react";

type EmpType = {
  name: string;
}
type EmpContextType = {
  emp: EmpType;
  setEmp: (user: EmpType) => void;
}

export const EmpContext = createContext({} as EmpContextType);

function UserProvider ({children})  {
  const [emp, setEmp] = useState({
    name: "Estuda Escolar"
  });

  const empProviderValue = useMemo(() => ({ emp, setEmp }), [emp, setEmp]);

  return (
    <EmpContext.Provider value={empProviderValue}>
      {children}
    </EmpContext.Provider>
  )
}

export const useEmp = () => useContext(EmpContext);

export default UserProvider;