export default (props) => {
  const { goToPage, currentPage, totalPages } = props.pagination;

  const render = () => {
    const rows = [];

    for (let index = 1; index <= totalPages; index++) {
      console.log("aopba");
      rows.push(
        <button className={`btn ${(currentPage == index) && "btn-active"}`} onClick={() => goToPage(index)}>{index}</button>
      );
    }

    return rows;
  }

  return (
    <div className="text-center mt-5">
      <div className="btn-group">
        {render()}
      </div>
    </div>
  );
};
