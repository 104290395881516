import React, { useMemo } from "react";
import H1 from "../../../components/H1";
import CardEmpresa from "src/components/CardEmpresa";

import { faker } from "@faker-js/faker";
import { bgCard } from "src/utils/styles";

function UsuariosEmpresas() {
  // fake de api
  const { data: DataEmpresas } = useMemo(() => {
    const defaultSchema = () => {
      return {
        id: faker.datatype.number(),
        name: faker.company.name().replace(/[^a-zA-Z ]/g, ""),
        cidade: faker.address.city(),
        estado: faker.address.state(),
        tipo: Math.random() > 0.5 ? "Gestor" : "Professor",
      };
    };

    return {
      data: Array(10)
        .fill(null)
        .map(() => defaultSchema()),
    };
  }, []);

  const [search, setSearch] = React.useState<string>("");

  const searchedEmpresas = useMemo(() => {
    if (!search) {
      return DataEmpresas;
    }
    return DataEmpresas.filter((empresa) => {
      return empresa.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [DataEmpresas, search]);

  return (
    <div className="mx-4 sm:mx-0 ">
      <H1>Acessar Instituição</H1>

      <div className="form-control my-4">
        <label className="input-group input-group-lg">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </span>
          <input
            className={`block w-full px-3 py-2 border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none ${bgCard}`}
            placeholder="Buscar"
            onChange={(e) => setSearch(e.target.value)}
          />
        </label>
      </div>

      <div className="flex">
        <div
          className={`grid grid-cols-3 gap-4 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6`}
        >
          {searchedEmpresas.map((empresa) => (
            <CardEmpresa
              key={empresa.id}
              //@ts-ignore
              name={empresa.name}
              type={empresa.tipo}
              id={empresa.id}
              city={empresa.cidade}
              state={empresa.estado}
              // @ts-ignore
              image={empresa?.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default UsuariosEmpresas;
