import { bgCard, hoverDefault, transtionControl } from "src/utils/styles";
import { DEFAULT_IMAGE } from "./constants";
// @ts-ignore
import styles from "./index.module.scss";

type CardEmpresaProps = {
  name: string;
  type: string;
  id: number;
  city: string;
  state: string;
  image?: string;
  onClick?: () => void;
};
function CardEmpresa(props: CardEmpresaProps) {
  const { name, type, id, city, state, image, onClick } = props;

  return (
    <div
      onClick={() => onClick?.()}
      className={`w-full shadow ${bgCard} px-4 justify-center align-middle items-center rounded-sm ${hoverDefault} hover:opacity-60 ${transtionControl}`}
    >
      <div className="flex justify-center items-center">
        <img
          className="flex rounded-full justify-center items-center object-cover"
          src={image ? image : DEFAULT_IMAGE}
        />
      </div>

      <div className={`flex flex-col justify-center items-center my-4 text-center`}>
        <h4 className="line-clamp-1 font-semibold text-xl">
          {name.length > 20 ? name.slice(0, 20) + "..." : name}
        </h4>
        <h6 className="text-sm">({type})</h6>
        <h6 className="text-sm">{id}</h6>
        <h6 className="text-sm mt-2 text-center">
          {city} - {state}
        </h6>
      </div>
    </div>
  );
}

export default CardEmpresa;
