import { Link } from "react-router-dom";
import { useEmp } from "src/contexts/lib/emp";
import Footer from "../components/Footer";
import { useUser } from "../contexts/lib/user";

interface TypePanelProps {
  component: React.FC;
  renderButtons?: boolean;
  simulado?: boolean;
}

function Panel (props: TypePanelProps) {
  const { component: Component, renderButtons = true, simulado } = props;

  const { user } = useUser();
  const { emp } = useEmp();

  return (
    <>
      <header className="p-5" style={{ backgroundColor: "#35A2DB" }}>
        <div className="flex justify-between items-center container mx-auto">
          <div className="flex items-center gap-2">
            <img
              src="https://cdn.estuda.com.br/interativo/design/1/logo.png"
              width={150}
            />

            {renderButtons && (
              <>
                <Link to="/">
                  <button
                    tabIndex={0}
                    className="btn btn-default btn-sm flex gap-2"
                  >
                    Inicio
                  </button>
                </Link>

                {emp ? (
                  <>
                    <div className="inline-flex rounded-md shadow-sm" role="group">
                      <div className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                        {emp.name}
                      </div>
                      <button type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                        Gerenciamento
                      </button>
                      <button type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-r-md hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                        Sair
                      </button>
                    </div>
                  </>
                ) : (
                  <Link to="/usuario/empresas">
                    <button tabIndex={0} className="btn btn-sm flex gap-2">
                      Acessar como professor
                    </button>
                  </Link>
                )}
              </>
            )}
          </div>

          <div className="flex gap-2">
            {renderButtons && (
              <>
                <button tabIndex={0} className="btn btn-sm flex gap-2">
                  Rede
                </button>

                <div className="dropdown">
                  <button tabIndex={0} className="btn btn-sm flex gap-2">
                    <img className="rounded-full" src={user.photo} width={18} />
                    <span>{user.name}</span>
                  </button>

                  <ul
                    tabIndex={0}
                    className="menu dropdown-content p-2 shadow bg-base-100 rounded-box w-52 mt-4"
                  >
                    <li>
                      <a>Meus Dados</a>
                    </li>
                    <li>
                      <a>Sair</a>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        </div>
      </header>

      {!!simulado && (
        <div className="bg-white">
          <div className="flex flex-col container mx-auto py-3">
            <h1>Nome do Simulado</h1>

            <div>
              botoes aqui...d
            </div>
          </div>
        </div>
      )}

      <div className="container px-5 mx-auto my-6 flex flex-col gap-3">
        <Component />
      </div>

      <Footer />
    </>
  );
}

export default Panel;