import React from "react";
import H1 from "src/components/H1";
import SimuladoAguardarResultado from "../../../components/SimuladoAguardarResultado";

export default () => {
  // AGUARDAR LIBERAR RESULTADO
  if (false) {
    return <SimuladoAguardarResultado />
  }

  return (
      <>
        <H1>Nome do Simulado</H1>
        <h2>Confira abaixo os seus resultados obtidos nesta avaliação.</h2>

        <h4>Avaliação iniciada no dia 16 de Abril de 2023</h4>

        <div className="flex gap-3">
          <div className="flex flex-col items-center justify-center bg-white p-3 w-64">
            0.11

            <h4>Nota</h4>
          </div>

          <div className="flex flex-col gap-3 flex-1">
            <div className="flex gap-3">
              <div className="flex flex-col gap-3 w-full">
                <div className="bg-white p-3 rounded w-full">
                  1 Certas
                </div>

                <div className="bg-white p-3 rounded w-full">
                  1 Erradas
                </div>

                <div className="bg-white p-3 rounded w-full">
                  1 Questões objetivas
                </div>
              </div>

              <div className="flex flex-col gap-3 w-full">
                <div className="bg-white p-3 rounded w-full">
                  1 Pontos fortes
                </div>

                <div className="bg-white p-3 rounded w-full">
                  1 Pontos medianos
                </div>

                <div className="bg-white p-3 rounded w-full">
                  1 Pontos a melhorar
                </div>
              </div>
            </div>

            <div className="bg-success p-3">
              100% A taxa de acerto considera as questões objetivas certas e erradas.
            </div>
          </div>

          <div className="flex flex-col gap-3 w-64">
            <div className="bg-white h-full">
              <div>Avaliação</div>

              <div>
                <h4>1</h4>
                <p>Participantes</p>
              </div>
            </div>

            <div className="bg-white">
              100% Taxa de acerto média dos outros estudantes.
            </div>
          </div>
        </div>

        <div>
          Gabarito
        </div>
      </>
  );
}
