export default (props) => {
  const { title, children, className } = props;

  return (
    <div className={`bg-white shadow-sm ${className}`}>
      {!!title && (
        <>
          <div className="p-3">
            {title}
          </div>

          <hr />
        </>
      )}

      <div className="flex flex-col gap-2 p-3">
        {children}
      </div>
    </div>
  );
};
