// @ts-ignore
import styles from "./index.module.scss";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";

export default ({children}) => {
  useDocumentTitle(children);

  return (
    <h1 className={styles.h1}>{children}</h1>
  );
};
