import React from "react";
import { BrowserRouter, Route, Routes as RouteDOM } from "react-router-dom";

import AuthMiddleware from "./middlewares/AuthMiddleware";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Usuarios from "./pages/Usuarios";
import Panel from "./layouts/Panel";
import SimualdoResultado from "./pages/Simulados/Resultados";
import SimualdoProva from "./pages/Simulados/Prova";
import SimualdoIniciar from "./pages/Simulados/Iniciar";
import UsuarioEmpresas from "./pages/Usuarios/Empresas";

import Index from './pages';
import Empresa from "./pages/Empresa";
import EmpresaSimulado from "./pages/Empresa/Simulado"
import AuthEmpMiddleware from "./middlewares/AuthEmpMiddleware";

function Routes() {
  return (
    <BrowserRouter>
      <RouteDOM>
        <Route path="/" element={<Panel component={Index} renderButtons={false} />} />
        <Route path="/login" element={<Login />} />

        <Route element={<AuthMiddleware />}>
          <Route path="/logout" element={<Logout />} />

          <Route path="/usuarios" element={<Panel component={Usuarios} />} />
          <Route path="/usuario/empresas" element={<Panel component={UsuarioEmpresas} />} />

          <Route path="/simulado/:id" element={<Panel component={SimualdoProva} />} />
          <Route path="/simulado/:id/iniciar" element={<Panel component={SimualdoIniciar} />} />
          <Route path="/simulado/:id/resultado" element={<Panel component={SimualdoResultado} />} />
        </Route>

        <Route element={<AuthEmpMiddleware />}>
          <Route path="/empresa" element={<Panel component={Empresa} />} />
          <Route path="/empresa/simulado" element={<Panel component={EmpresaSimulado} simulado={true} />} />
        </Route>
      </RouteDOM>
    </BrowserRouter>
  );
}

export default Routes;
