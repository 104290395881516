import './index.css';
import Provider from './contexts';
import Routes from './routes';

export default function App() {
  return (
    <Provider>
      <Routes/>
    </Provider>
  );
}
