import React from "react";
import { useQuery } from "react-query";
import CardSimulado from "../../components/CardSimulado";
import H1 from "../../components/H1";
import { getProvas } from "../../services/UsuarioProva";

export default function Usuarios() {
  const { data, isLoading } = useQuery("usuarioProvas", getProvas);

  if (isLoading) {
    return <>Carregando...</>
  };

  return (
    <>
      <H1>Avaliações - Painel do Aluno</H1>

      <div className="columns-2">
        {
        // @ts-ignore
        !!data && data.map(prova => {
          return <CardSimulado prova={prova} />;
        })}
      </div>
    </>
  );
}
