import Questao from "./Questao";

export default (props) => {

  const { questoes } = props;

  return (
    <div className="flex flex-col gap-5">
      {questoes.map(questao => {
        return (
          <Questao key={questao.id} questao={questao} />
        )
      })}
    </div>
  );
};
