const getProvas = async () => {
  return () => {
    return [
      {
        id: 1,
        status: 1,
        resolver: {
          id: 444,
          "title": "Enem Interativo - Prova 1 - 3º Serie - 2023"
        }
      },
      {
        id: 2,
        status: 0,
        resolver: {
          id: 444,
          "title": "Enem Interativo - Prova 2 - 3º Serie - 2023"
        }
      },
      {
        id: 3,
        status: 1,
        resolver: {
          id: 444,
          "title": "Enem Interativo - Prova 2 - 3º Serie - 2023"
        }
      },
      {
        id: 3,
        status: 1,
        resolver: {
          id: 444,
          "title": "Enem Interativo - Prova 2 - 3º Serie - 2023"
        }
      },
      {
        id: null,
        status: null,
        resolver: {
          id: 444,
          "title": "Enem Interativo - Prova 2 - 3º Serie - 2023"
        }
      }
    ];
  }
};

export { getProvas };