import { Link } from "react-router-dom";
import {
  bgCard,
  textDark,
  textHover,
  transtionControl,
} from "src/utils/styles";
import Panel from "../Panel";

function CardSimulado(props) {
  const { prova } = props;

  const provaLink = () => {
    if (prova.status == 0) {
      return `/simulado/${prova.id}`;
    } else if (prova.status == 1) {
      return `/simulado/${prova.id}/resultado`;
    }

    return `/simulado/${prova.resolver.id}/iniciar`;
  };

  const provaProgresso = () => {
    if (prova.status == 0) {
      return "Continuar";
    } else if (prova.status == 1) {
      return "Resultados";
    }

    return "Começar Agora";
  };

  return (
    <Link to={provaLink()} className={`w-full inline-block my-2 ${bgCard}`}>
      <Panel>
        <h4 className="font-bold text-xl">{prova.resolver.title}</h4>
        <span
          className={`${textDark} text-primary-600 ${textHover} hover:text-primary-700 hover:underline ${transtionControl} `}
        >
          {provaProgresso()}
        </span>
      </Panel>
    </Link>
  );
}

export default CardSimulado;
