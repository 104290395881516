import React from "react";
import { useQuery } from "react-query";
import CardSimulado from "src/components/CardSimulado";
import H1 from "src/components/H1";
import { getProvas } from "src/services/UsuarioProva";

export default function EmpresaSimulado() {
  return (
    <>
      <H1>Visão Geral</H1>

      ... Informações do simulado aqui...
    </>
  );
}
