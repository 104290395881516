import Panel from "../../Panel";
import Resposta from "./Resposta";

export default (props) => {
  const { questao } = props;

  return (
    <Panel title="Questão 1">
      <div dangerouslySetInnerHTML={{__html: questao.enunciado}} />

      <Resposta questao={questao} />
    </Panel>
  );
};
