import UserContext from "./lib/user";
import EmpContext from "./lib/emp";
import EnvironmentContext from "./environment";

type Props = {
  children: React.ReactNode;
}

export default function Provider (props: Props) {
  const { children } = props;

  return (
    <EnvironmentContext>
      <UserContext>
        <EmpContext>
          {children}
        </EmpContext>
      </UserContext>
    </EnvironmentContext>
  )
}
