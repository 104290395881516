import React from "react";
import { useQuery } from "react-query";
import CardSimulado from "../../components/CardSimulado";
import H1 from "../../components/H1";
import { getProvas } from "../../services/UsuarioProva";

export default function Empresa() {
  const { data, isLoading } = useQuery("usuarioProvas", getProvas);

  if (isLoading) {
    return <>Carregando...</>
  };

  return (
    <>
      <H1>NOME DA EMPRESA</H1>

      {(data.length > 16) && (
        <div className="form-control">
          <label className="input-group input-group-lg">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </span>
            <input className="input input-bordered w-full" placeholder="Buscar" />
          </label>
        </div>
      )}

      <div className="columns-2">
        {
        // @ts-ignore
        !!data && data.map(prova => {
          return <CardSimulado prova={prova} />;
        })}
      </div>
    </>
  );
}
