import React from "react";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import Questoes from "../../../components/Questoes";
import Progresso from "../../../components/Questoes/Progresso";
import { usePagination } from "src/hooks/usePagination";
import Paginacao from "src/components/Paginacao";
import { QUESTOES_POR_PAGINA } from "src/utils/consts";

export default () => {
  useDocumentTitle("");

  const avaliacao = {
    "id": 3231231,
    "titulo": "Nome do Simulado"
  };

  const questoes = [
    {
      "id": 1,
      "enunciado": "O lagartinho-das-dunas (<em>Calyptommatus leiolepis</em>), representado na imagem, é uma espécie de réptil que não possui patas dianteiras, e as traseiras, em destaque, são atrofiadas. Essa espécie apresenta hábitos fossoriais, ou seja, vive sob o solo arenoso, portanto a presença de patas seria desnecessária para seu modo de vida. <img src=\"https://d23vy2bv3rsfba.cloudfront.net/questoes_imagens/0_fa2f9b80d7fa39e8b580966b01d66204_4085895.jpg.png\">",
      "qst_resposta1": "a) as patas atrofiadas podem ser consideradas órgãos vestigiais, pois não possuem uma função evidente no modo de vida dessa espécie.",
      "qst_resposta2": "b) as patas atrofiadas podem ser consideradas órgãos análogos, pois possuem resquicíos que permitem compará-las com espécies de lagartos com patas."
    },
    {
      "id": 2,
      "enunciado": "Teste :D",
    }
  ];

  const pagination = usePagination(questoes.length, QUESTOES_POR_PAGINA);

  const questoesDaPagina = () => {
    return questoes.slice((pagination.currentPage - 1) * QUESTOES_POR_PAGINA, pagination.currentPage * QUESTOES_POR_PAGINA);
  }

  return (
    <>
      <h1 style={{fontSize:32}}>{avaliacao.titulo}</h1>

      <div className="flex justify-between gap-5">
        <div className="w-full">
          <Questoes questoes={questoesDaPagina()} />

          {(questoes.length > QUESTOES_POR_PAGINA) && <Paginacao pagination={pagination} />}
        </div>

        <div className="flex flex-col gap-3 text-xs" style={{minWidth:300}}>
          <Progresso questoes={questoes} pagination={pagination} />

          <Link to={`/simulado/${avaliacao.id}/resultado`}>
            <button className="btn w-full">Finalizar</button>
          </Link>
        </div>
      </div>
    </>
  );
}
