import { createContext, useContext, useMemo, useState } from "react";

type UserType = {
  name: string;
  photo: string;
}
type UserContextType = {
  user: UserType;
  setUser: (user: UserType) => void;
}

export const UserContext = createContext({} as UserContextType);

function UserProvider ({children})  {
  const [user, setUser] = useState({
    name: "Bruno Caitano",
    photo: "https://estudavestdev.s3.amazonaws.com/usuarios_fotos/5868215_923e15ee4d310236068c66e9ecb1c6c4_5868215.jpg"
  });

  const userProviderValue= useMemo(() => ({ user, setUser }), [user, setUser]);

  return (
    <UserContext.Provider value={userProviderValue}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext);

export default UserProvider;