import React from "react";
import Panel from "../../../components/Panel";
import { useDocumentTitle } from "../../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";

export default () => {
  useDocumentTitle("");

  return (
    <>
      <h1 style={{fontSize:32}}>Simulado</h1>
      <h2 style={{fontSize:26}}>Nome do Simulado</h2>

      <Panel>
        <p>Data para começar <b>20/03/2023 14:49</b></p>
        <p><b>5</b> Questões</p>
      </Panel>

      <Link to="/simulado/3231231">
        <button className="btn btn-success w-full">Iniciar Simulado</button>
      </Link>
    </>
  );
}
