import { QUESTOES_POR_PAGINA } from "src/utils/consts";
import { getPageOfItems } from "src/utils/functions";

export default (props) => {
  const { questoes, pagination } = props;
  const { goToPage } = pagination;

  return (
    <div className="flex flex-col gap-2">
      <div className="border bg-white py-1 px-2 rounded-lg">0 / {questoes.length} Questões</div>

      <div className="grid grid-cols-6 gap-1">
        {questoes.map((questao, index) => {
          const questaoNumber = index + 1;

          return (
            <button key={questao.id} className="btn btn-xs" onClick={() => goToPage(getPageOfItems(questaoNumber, QUESTOES_POR_PAGINA))}>{questaoNumber}</button>
          )
        })}
      </div>

      <div className="border bg-white py-1 px-2 flex rounded-lg justify-between gap-5">
        <span>Data Final</span>
        <span className="font-bold">03/04/2023 23:59</span>
      </div>
    </div>
  );
};
