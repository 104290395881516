export default (props) => {
  const { questao } = props;

  const render = () => {
    const rows = [];

    for (let index = 1; index <= 5; index++) {
      const resposta = questao[`qst_resposta${index}`];

      if (resposta) {
        rows.push(
          <div className="flex gap-2 p-1 border">
            <input type="radio" className="radio" name="questao" />
            <span dangerouslySetInnerHTML={{__html: resposta}} />
          </div>
        );
      }
    }

    return rows;
  }

  return (
    <div className="flex flex-col gap-2">
      {render()}
    </div>
  );
};
