// @ts-ignore
import styles from "./index.module.scss";
import { useEnvironment } from "src/contexts/environment";

export default () => {
  const { environment } = useEnvironment();

    return (
      <footer className="py-3 text-center">
        {environment.name}
      </footer>
    );
};
